import mapboxgl from 'mapbox-gl'
import turf from 'turf-extent';

import svgMaker from '../images/svg/map-marker.svg'

import Lottie from 'lottie-web';
import Lottie_SVG from '../images/svg/lottie_city_pass.json';


if (document.getElementById('accueil-citypass')) {
  let citypass = document.getElementById('accueil-citypass');
  citypass.addEventListener('click', () => {
    window.open('https://www.nimescitypass.com/lang/fr', '_blank');
  })
}

var mbgl = window.mbgl = mapboxgl;
var lottie = window.lottie = Lottie;

mbgl.accessToken = 'pk.eyJ1IjoiYmNuY28iLCJhIjoiY2wxeHEyNm5xMDBvOTNjcmE4bHRzZHJyeiJ9.d4ATsz8VW2qpcDG-Ic9Sbg';

if (document.getElementById('accueil-citypass')) {
  var lottie_City = window.lottie_city = lottie.loadAnimation(
    {
      container: document.getElementById('accueil-citypass'), // the dom element that will contain the animation
      renderer: 'svg',
      loop: false,
      autoplay: false,
      speed: 10,
      animationData: Lottie_SVG, // the path to the animation json
    }
  )
  lottie_City.setSpeed(10);

  var directionMenu = 1;
  document.getElementById('accueil-citypass').addEventListener('mouseenter', () => {
    lottie_City.setDirection(directionMenu);
    lottie_City.play();
  });

  document.getElementById('accueil-citypass').addEventListener('mouseleave', () => {
    lottie_City.setDirection(-directionMenu);
    lottie_City.play();
  });
  console.log('window.lottie', lottie_City);
}


if (document.getElementById('map')) {


  const map = new mapboxgl.Map({
    container: 'map',
// Choose from Mapbox's core styles, or make your own style with Mapbox Studio
    style: 'mapbox://styles/mapbox/streets-v12',
    center: [-103.5917, 40.6699],
    zoom: 3,
  });

  map.scrollZoom.enable();
  map.on('load', () => {

    const width = 30;
    const height = 40;

    const img = new Image(width, height);
// map is your Mapbox GL map object
    img.onload = () => map.addImage('markerPointer', img);
    img.src = svgMaker;
    window.mapTest = map;

    console.log(img);
// Add a new source from our GeoJSON data and
// set the 'cluster' option to true. GL-JS will
// add the point_count property to your source data.
    map.addSource('apidaeData', {
      type: 'geojson',
// Point to GeoJSON data. This example visualizes all M1.0+ apidaeData
// from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
      data: {
        'type': 'FeatureCollection',
        'features': [],
      },
      cluster: true,
      clusterMaxZoom: 14, // Max zoom to cluster points on
      clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
    });

    window.updateMapFromGeoJson = function (ArrayData) {


      let tempArray = [];
      ArrayData.forEach(function (item) {
        tempArray.push({
          'type': 'Feature',
          'properties': item.properties,
          'geometry': item.geoJson,
        })
      })


      let dataSource = {
        'type': 'FeatureCollection',
        'crs': {'type': 'name', 'properties': {'name': 'urn:ogc:def:crs:OGC:1.3:CRS84'}},
        'features': tempArray,
      }
      map.getSource('apidaeData').setData(dataSource);

      if (dataSource.features.length > 1) {
        var bbox = turf(dataSource);
        map.fitBounds(bbox, {padding: 100});
      } else {
        map.flyTo({
          center: dataSource.features[0].geometry.coordinates,
          zoom: 12,
        })


      }


    }


    map.addLayer({
      id: 'clusters',
      type: 'circle',
      source: 'apidaeData',
      filter: ['has', 'point_count'],
      paint: {
// Use step expressions (https://docs.mapbox.com/style-spec/reference/expressions/#step)
// with three steps to implement three types of circles:
//   * Blue, 20px circles when point count is less than 100 -#51bbd6
//   * Yellow, 30px circles when point count is between 100 and 750
//   * Pink, 40px circles when point count is greater than or equal to 750
        /*
        [
          'step',
          ['get', 'point_count'],
          '#1A2740',
          100,
          '#f1f035',
          750,
          '#f28cb1' ],
          */
        'circle-color': '#1A2740',
        'circle-radius': [
          'step',
          ['get', 'point_count'],
          20, 5,
          25, 10,
          30, 15,
          35, 20,
          40,
        ],
        'circle-stroke-width': 10,
        'circle-stroke-color': '#8C8C8C',
        'circle-stroke-opacity': 0.5,
      },
    });

    map.addLayer({
      id: 'cluster-count',
      type: 'symbol',
      source: 'apidaeData',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': ['get', 'point_count_abbreviated'],
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 25,
      },
      paint: {
        'text-color': 'white',
      },
    });

    // map.addLayer({
    //   id: 'unclustered-point',
    //   type: 'circle',
    //   source: 'apidaeData',
    //   filter: ['!', ['has', 'point_count']],
    //   paint: {
    //     'circle-color': '#11b4da',
    //     'circle-radius': 4,
    //     'circle-stroke-width': 1,
    //     'circle-stroke-color': '#fff',
    //   },
    // });
    map.addLayer({
      id: 'unclustered-point',
      type: 'symbol',
      source: 'apidaeData',
      filter: ['!', ['has', 'point_count']],
      layout: {
        'icon-image': 'markerPointer',
        'icon-size': 1,
      },
    });


// inspect a cluster on click
    map.on('click', 'clusters', (e) => {
      const features = map.queryRenderedFeatures(e.point, {
        layers: ['clusters'],
      });
      const clusterId = features[0].properties.cluster_id;
      map.getSource('apidaeData').getClusterExpansionZoom(
        clusterId,
        (err, zoom) => {
          if (err) return;

          map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom,
          });
        },
      );
    });

    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    });
    map.on('mouseenter', 'unclustered-point', (e) => {
// Change the cursor style as a UI indicator.
      map.getCanvas().style.cursor = 'pointer';

// Copy coordinates array.
      const coordinates = e.features[0].geometry.coordinates.slice();
      const description = e.features[0].properties.description;

// Ensure that if the map is zoomed out such that multiple
// copies of the feature are visible, the popup appears
// over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

// Populate the popup and set its coordinates
// based on the feature found.
      popup.setLngLat(coordinates).setHTML(description).addTo(map);
    });

    map.on('mouseleave', 'unclustered-point', () => {
      map.getCanvas().style.cursor = '';
      popup.remove();
    });

    map.on('click', 'unclustered-point', (e) => {
      window.location.href = e.features[0].properties.id;
    });

// // When a click event occurs on a feature in
// // the unclustered-point layer, open a popup at
// // the location of the feature, with
// // description HTML from its properties.
//   map.on('click', 'unclustered-point', (e) => {
//     const coordinates = e.features[0].geometry.coordinates.slice();
//     const mag = e.features[0].properties.mag;
//     const tsunami =
//       e.features[0].properties.tsunami === 1 ? 'yes' : 'no';
//
// // Ensure that if the map is zoomed out such that
// // multiple copies of the feature are visible, the
// // popup appears over the copy being pointed to.
//     while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
//       coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
//     }
//
//     new mapboxgl.Popup()
//       .setLngLat(coordinates)
//       .setHTML(
//         `magnitude: ${mag}<br>Was there a tsunami?: ${tsunami}`
//       )
//       .addTo(map);
//   });

    map.on('mouseenter', 'clusters', () => {
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', 'clusters', () => {
      map.getCanvas().style.cursor = '';
    });
  });
}

$(document).ready(function () {

  setTimeout(function () {
    $('.reset_variations').trigger('click');
  }, 10);

  const nonceAloa = $('#product-aloa-nonce').val();
  const idAloa = $('#aloa_id').val();


  const year = $('#annee');
  const month = $('#mois');
  const day = $('#jour');
  const hours = $('#heure');
  const tarif = $('#tarif');
  const spinner = $('.spinner')
  spinner.hide();
  const ticketSelected = $('#ticket_selected');

  year.parent().closest('tr').hide();
  month.parent().closest('tr').hide();
  day.parent().closest('tr').hide();
  hours.parent().closest('tr').hide();
  tarif.parent().closest('tr').hide();

  console.log('year', year.length > 0)
  console.log('month', month.length > 0)
  console.log('day', day.length > 0)
  console.log('hours', hours.length > 0)
  if (year.length > 0 && month.length > 0 && day.length > 0 && hours.length > 0) {
    tarif.parent().closest('tr').hide();

  }


  const datePicker = $('#product-date-picker');

  //test
  let arrayDay = ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam'];
  let arrayMonth = ['Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre'];

  if (window.listDateValid !== undefined) {

    datePicker.daterangepicker({
      singleDatePicker: true,
      autoApply: true,
      startDate: (new Date(window.listDateValid[0]) < new Date() ? new Date() : new Date(window.listDateValid[0])),
      'locale': {
        // 'format': 'YYYY-MM-DD',
        'format': 'DD-MM-YYYY',
        'fromLabel': 'From',
        'toLabel': 'To',
        'minDate': new Date(window.listDateValid[0]),
        'maxDate': new Date(window.listDateValid[window.listDateValid.length - 1]),
        'customRangeLabel': 'Custom',
        'weekLabel': 'W',
        'daysOfWeek': arrayDay,
        'monthNames': arrayMonth,
        'firstDay': 1,
      },
      isInvalidDate: function (date) {
        // let dates = ['18-03-2024','19-03-2024','20-03-2024','21-03-2024']

        // console.log('date1',date.format('YYYY-MM-DD'));
        // console.log('date',date.unix());
        // console.log('new date',Date.now());
        // console.log('new date test ',(date.valueOf()+86400000) < Date.now());
        // console.log('new date test ',((date.valueOf()+86400000)  - Date.now()));


        if (window.listDateValid.includes(date.format('YYYY-MM-DD')) && (date.valueOf() + 86400000) > Date.now()) {
          return false;
        }

        return true;
      },
      numberOfMonths: 1,
    });
  }


  //fin test


  hours.on('change', function () {
    updateOrCreateStckField(stockByHours[$(this).val()]);
  })

  datePicker.on('change', function () {

    $(this).closest('.a');


    hours.val('');
    hours.change();
    spinner.show();

    var splittedDate = $(this).val().split('-');
    var year = splittedDate[2];
    var month = splittedDate[1];
    var day = splittedDate[0];

    let data = {
      'id_aloa': idAloa,
      'dateStart': year + '-' + month + '-' + day,
    };
    window.aloaAjaxRequest('date_selected', data, $('#product-aloa-nonce').val(), callBackTest)

  })
  let stockByHours = [];


  let arrayTicket = [];

  hours.on('change', function () {

    var splittedDate = datePicker.val().split('-');
    var year = splittedDate[2];
    var month = splittedDate[1];
    var day = splittedDate[0];

    let data = '' + arrayTicket[$(this).val()] + '/' + year + '-' + month + '-' + day;

    ticketSelected.val(data).change();
    console.log('id ticket ', data);
    console.log('Date Now ', Date.now());
    setTimeout(reselectHours, 100);

  });


  function updateOrCreateStckField(stock = 'veuillez selectioner une heure') {
    const stockTicket = $('#stock-ticket');

    if (stockTicket.length === 0) {
      $('.variations:not(.custom-date-picker) tbody').append('<tr id="stock-ticket">' +
        '<th class="label"><label for="jour">Stock</label></th>\n' +
        '<td class="value">' +
        '<span >' + stock + '</span>' +
        '</td>' +
        '</tr>')
      $('#stock-ticket').hide();
    } else {
      stockTicket.find('span').text(stock)
    }

    // console.log('stockTicket,stock',hours,hours.val());

    if (hours.val() === '') {
      stockTicket.hide()
    } else {
      stockTicket.show()
    }
  }

  function reselectHours() {

    hours.find('option').each(function () {
      if ($(this).val() !== '') {
        $(this).hide();
      }
    });
    Object.entries(currentEntry).forEach(entry => {
      const [key, value] = entry;
      const currentEntryValue = value.timeStart + ' => ' + value.timeEnd;
      console.log(key)

      stockByHours[currentEntryValue] = value.availableQuantity;
      hours.find('option').each(function () {
        arrayTicket[currentEntryValue] = value.id;
        if ($(this).val() === currentEntryValue) {
          $(this).show();
        }
      });
    });
  }

  let currentEntry = [];

  function callBackTest(data) {
    console.log('data', data);
    const dateItem = new Date(data.date);
    const dayDate = dateItem.getDate() < 10 ? '0' + dateItem.getDate() : dateItem.getDate();
    const yearDate = dateItem.getFullYear();
    const monthDate = arrayMonth[dateItem.getMonth()];

    day.val(dayDate).change();
    year.val(yearDate).change();
    month.val(monthDate).change();
    currentEntry = data.result;
    // $("div.id_100 select").val("val2").change();
    hours.find('option').each(function () {
      if ($(this).val() !== '') {
        $(this).hide();
      }
    });
    Object.entries(currentEntry).forEach(entry => {
      const [key, value] = entry;
      const currentEntryValue = value.timeStart + ' => ' + value.timeEnd;

      stockByHours[currentEntryValue] = value.availableQuantity;
      console.log(key, dayDate + '-' + dateItem.getMonth() + '-' + yearDate + ' ' + value.timeStart);
      let currentTimeStamp = Date.parse(dayDate + ' ' + monthDate + ' ' + yearDate + ' ' + value.timeStart);
      var testdate = new Date(currentTimeStamp);
      var nowdate = new Date();
      // console.log('testdate', testdate);
      // console.log('testdate > ', nowdate < testdate);

      if (nowdate < testdate) {

        console.log('currentTimeStamp', currentTimeStamp);
        hours.find('option').each(function () {
          arrayTicket[currentEntryValue] = value.id;
          if ($(this).val() === currentEntryValue) {
            $(this).show();
          }
        });

      }

    });
    hours.parent().closest('tr').show();
    // tarif.parent().closest('tr').show();
    // updateOrCreateStckField(data.result.stock);
    console.log('stockByHours', stockByHours)
    spinner.hide();
  }

  datePicker.change();

  console.log('ready!', nonceAloa);
});
